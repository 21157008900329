.page {
  height: 100%;
  min-height: 100vh;
  display: flex;
  background-color: #f3f2f2;
}

.sidebar {
  min-width: 140px;
  background-color: white;
  height: 100vh;
  border-radius: 0 30px 30px 0;
  position: sticky;
  top: 0;
  box-shadow: 0px 0px 10px 0px rgba(165, 163, 163, 0.75);

  &-content {
    padding: 30px 0 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: calc(100vh - 90px);
    width: 140px;
  }

  &-links {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &-link {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 70px;
    font-family: JosefinSans;
    font-size: 16px;
    color: black;

    &:not(:last-child) {
      margin-bottom: 30px;
    }

    img {
      margin-bottom: 8px;
      max-width: 22px;
      max-height: 20px;
    }

    &.active {
      background-color: #ffffff4d;
    }
  }

  &-account {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    .user-avatar {
      width: 40px;
      height: 40px;
      border-radius: 20px;
    }

    .divider {
      margin: 20px 0;
      width: 80px;
      height: 1px;
      background-color: #ffffff33;
    }

    .logout-button {
      font-family: JosefinSans;
      font-size: 16px;
      color: black;
      background: none;
      border: none;
    }
  }
}

.content {
  padding: 60px 100px 60px 80px;
  width: calc(100vw - 260px);
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;
}
