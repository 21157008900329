// colors
$main-black: #0D0C0C;
$gray: #C5C3C3;
$light-gray: #F0EFEF;
$green: #1CB05E;
$green200: #478251;
$yellow: #F4BC41;

$breakpoint-mobile-s: 375px;
$breakpoint-mobile-m: 475px;
$breakpoint-mobile: 768px;
$breakpoint-tablet: 993px;
$breakpoint-laptop: 1200px;