.rounded-input {
  width: calc(100% - 50px);
  height: 50px;
  border: 1px solid #dbdbdb;
  border-radius: 25px;
  padding: 0 25px;
  font-size: 16px;
  font-family: JosefinSans;

  &::placeholder {
    color: $gray;
  }

  &:focus {
    outline: none;
  }
}
.rounded-container {
  width: calc(100% - 50px);
  border: 1px solid #dbdbdb;
  border-radius: 30px;
  padding: 5px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 16px;
  font-family: JosefinSans;
  display: flex;
  flex-direction: row;
  input {
    border: none;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 16px;
    font-family: JosefinSans;
    &::placeholder {
      color: $gray;
    }
  }
  &.with-select {
    input {
      width: 60%;
    }
    select {
      border: none;
      width: 40%;
      padding-top: 10px;
      padding-bottom: 10px;
      font-size: 16px;
      font-family: JosefinSans;
      background: transparent;
    }
  }
}

.input-container {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 42px;
  margin-bottom: 24px;
  .wrapper {
    display: flex;
    align-items: center;
    position: relative;

    &.disabled {
      opacity: 0.5;
    }

    .postfix {
      position: absolute;
      right: 15px;
      width: 30px;
      text-align: right;
      font-family: JosefinSans;
      font-size: 14px;
      
      .select-arrow {
        position: relative !important;
        opacity: 1 !important;
        animation: none !important;
        z-index: 10 !important;
        top: 2px !important;
        right: 0 !important;
        transition: all 0.3s ease-in;
      }

      &.active .select-arrow {
        transform: rotate(180deg);
        top: 0px !important;
      }
    }

    .prefix {
      position: absolute;
      left: 8px;
      top: 25%;
      width: 30px;
    }
  }

  label {
    margin-bottom: 10px;
    font-family: JosefinSans;
    font-size: 16px;
  }

  input {
    height: 42px;
    border: 1px solid #dbdbdb;
    border-radius: 8px;
    padding: 0 14px;
    flex-grow: 1;
    font-size: 14px;
    font-family: JosefinSans;
    max-width: calc(100% - 30px);

    &::placeholder {
      color: $gray;
    }

    &.with-postfix {
      padding-right: 60px;
      max-width: calc(100% - 75px);
    }

    &.with-prefix {
      padding-left: 40px;
    }
  }
}

.form-select {
  width: 100%;
  min-height: 42px;
}
.select-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;

  .wrapper {
    display: flex;
    align-items: center;
    position: relative;

    .arrow {
      transform: rotate(90deg);
      position: absolute;
      right: 15px;
      text-align: right;
      font-family: JosefinSans;
      font-size: 14px;
      display: none;
    }
  }

  label {
    margin-bottom: 10px;
    font-family: JosefinSans;
    font-size: 16px;
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    height: 40px;
    border: 1px solid #dbdbdb;
    border-radius: 7px;
    padding: 0 15px;
    flex-grow: 1;
    font-size: 14px;
    font-family: JosefinSans;
    background-color: white;
    color: $gray;

    &.selected {
      color: $main-black;
    }
  }
}

.checkbox {
  display: flex;
  align-items: center;
  font-family: JosefinSans;
  font-size: 14px;
  text-transform: capitalize;
  margin-bottom: 12px;

  &.active {
    img {
      visibility: visible;
    }
  }

  &:hover {
    cursor: pointer;
  }

  .box {
    min-width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #dbdbdb;
    border-radius: 3px;
    margin-right: 10px;
  }

  img {
    visibility: hidden;
  }
}

.suggest-input {
  position: relative;
  display: flex;
  flex-direction: column;

  &.loading {
    img {
      opacity: 1;
    }
  }

  input {
    z-index: 5;
  }

  img:not(.checkbox-img) {
    opacity: 0;
    transition: opacity 0.3s;
    position: absolute;
    z-index: 4;
    width: 20px;
    right: 10px;
    top: 11px;
    animation-name: rotate;
    animation-duration: 1s;
    animation-iteration-count: infinite;
  }

  .wrapper {
    z-index: 1;
  }

  .options {
    z-index: 2;
    position: absolute;
    left: 0;
    right: 0;
    top: 37px;
    background-color: white;
    border: 1px solid #dbdbdb;
    border-top-width: 0;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    padding-top: 5px;

    visibility: hidden;
    opacity: 0;

    &.active {
      visibility: visible;
      opacity: 1;
    }

    .option {
      height: 40px;
      display: flex;
      align-items: center;
      padding: 0 15px;
      font-size: 14px;
      font-family: JosefinSans;

      &:hover {
        cursor: pointer;
        background-color: $light-gray;
      }
    }
  }
}

.error {
  min-height: 15px;
  font-size: 14px;
  font-family: JosefinSans;
  color: red;
}

.number-without-controller {
  &[type="number"] {
    -moz-appearance: textfield;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
}
.error-message {
  display: block;
  border-radius: 5px;
  margin-top: 20px;
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
  padding: 10px;
  border: solid 1px rgb(117, 43, 43);
  color: rgb(117, 43, 43);
  background: rgb(252, 186, 186);
}

.wrapper {
  .select:focus,
  .input:focus {
    border-color: black;
  }
}
.row-title {
  margin-bottom: 10px;
}
.display-container {
  margin-bottom: 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  label {
    font-size: 10px;
    color: #999;
    font-style: italic;
    font-family: Arial, Helvetica, sans-serif;
  }
  input,
  select {
    font-size: 14px;
  }
  div {
    margin-bottom: 0;
  }
}

.display-label {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 12px;
  font-size: 14px;
  color: #999;
}
