@font-face {
  font-family: MontserratRegular;
  src: url('../fonts/Montserrat-Regular.ttf');
}

@font-face {
  font-family: MontserratMedium;
  src: url('../fonts/Montserrat-Medium.ttf');
}

@font-face {
  font-family: MontserratSemiBold;
  src: url('../fonts/Montserrat-SemiBold.ttf');
}

@font-face {
  font-family: JosefinSans;
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/JosefinSans-Regular.ttf');
}

@font-face {
  font-family: JosefinSans;
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/JosefinSans-Medium.ttf');
}
@font-face {
  font-family: JosefinSans;
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/JosefinSans-SemiBold.ttf');
}
@font-face {
  font-family: JosefinSans;
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/JosefinSans-Bold.ttf');
}