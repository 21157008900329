.page-header {
  display: flex;
  flex-direction: column;

  &--dates {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 40px;

    h2 {
      margin-right: 30px;
    }
    a {
      margin: 0 !important;
      color: blue !important;
    }
    button {
      margin-left: 15px;
      padding: 5px;
      width: 90px;
      border: 1px solid black;
      border-radius: 25px;
      background-color: transparent;
      cursor: pointer;
    }
  }

  a {
    color: $main-black;
    font-size: 16px;
    text-decoration: none;
    margin-bottom: 30px;
    display: flex;
    align-items: center;

    img {
      height: 13px;
      margin-right: 20px;
    }
  }

  h2 {
    color: $main-black;
    font-size: 32px;
    line-height: 37px;
    // margin-bottom: 40px;
  }

  h3 {
    color: $gray;
    font-size: 24px;
    line-height: 27px;
    margin-bottom: 10px;
  }
}

.day {
  display: flex;
  align-items: center;

  @media (max-width: 1300px) {
    flex-direction: column;
    align-items: flex-start;

    &-date {
      margin-left: 0 !important;
    }
  }

  &-dayname {
    font-size: 24px;
    color: $main-black;
  }

  &-date {
    font-size: 20px;
    color: $gray;
    margin-left: 15px;
  }
}

.plan-tabs {
  display: flex;
  gap: 20px;
  border-bottom: 1px solid #eee;
  margin-bottom: 30px;

  .tab-button {
    padding: 6px 10px;
    background: transparent;
    border: none;
    font-size: 20px;
    font-family: JosefinSans;

    &.active {
      border-bottom: 1px solid black;
    }
  }
}

.plan-columns {
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(7, 1fr);
  grid-tempalte-rows: auto;

  &.aacp {
    margin-top: 20px;
    grid-gap: 25px;
    grid-template-columns: repeat(4, 1fr);
  }

  .row-title {
    margin-top: 30px;
    margin-bottom: -15px;
    min-height: 19px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 18px;
    letter-spacing: 2px;
  }
}

.card {
  margin-top: 30px;
  height: 230px;
  width: 100%;
  border: 1px solid #dbdbdb;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  background-color: #fff;

  &--empty {
    border-style: dashed;
    justify-content: center;
    overflow: hidden;
  }

  &-body {
    flex-grow: 1;
    padding: 20px 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &-image {
    background-size: cover;
    background-position: center;
    height: 130px;
    border-radius: 9px 9px 0 0;
  }

  &-name {
    font-family: JosefinSans;
    font-size: 16px;
    line-height: 17px;
    text-align: center;
    line-clamp: 2;
    margin-top: -4px;
  }

  & &-counter-button {
    border-radius: 50%;
    background-color: #fff;
    width: 22px;
    height: 22px;

    & > img {
      width: 100%;
    }
  }

  & &-counter-value {
    font-weight: 600;
    font-size: 14px;
    text-align: center;
  }

  &-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    & > div:nth-child(2) {
      position: absolute;
      right: 0;
    }
  }

  &-button {
    height: 100%;
    font-family: JosefinSans;
    font-size: 16px;
    color: $gray;
    border: none;
    background: white;

    &:hover {
      transition: 0.2s ease-in-out;
      cursor: pointer;
      color: $main-black;
    }
  }
}

.counter {
  display: flex;
  align-items: center;

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &-button {
    border: 0;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 18px;
  }

  &-value {
    min-width: 13px;
    text-align: center;
    font-size: 14px;
    margin: 0 10px;
    font-weight: 700;
  }
}

.tooltip {
  position: relative;
  padding: 10px;
  margin: -10px;
  width: 5px;

  &__loader {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    width: 24px;
    height: 24px;

    &:after {
      content: " ";
      display: block;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      border-width: 1px;
      border-style: solid;
      border-color: #dbdbdb transparent #dbdbdb transparent;
      animation: lds-dual-ring 1.2s linear infinite;
    }

    @keyframes lds-dual-ring {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }

  &-dots {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    padding: 10px;
    margin: -10px;

    &:hover {
      cursor: pointer;
    }

    * {
      width: 4px;
      height: 4px;
      border-radius: 2px;
      background-color: #000000;

      &:not(:last-child) {
        margin-bottom: 3px;
      }
    }
  }

  &-body {
    z-index: 2;
    transition: opacity, visibility 0.2s ease-in-out;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    min-width: 110px;
    background: #ffffff;
    border: 1px solid rgba(90, 79, 255, 0.1);
    box-shadow: 0 2px 40px 20px rgba(33, 25, 156, 0.04);
    border-radius: 5px;
    transform: translateX(calc(-100% + 20px));
    top: 45px;
    padding: 20px;
    display: flex;
    flex-direction: column;

    &.open {
      visibility: visible;
      opacity: 1;
      z-index: 100;

      &.last {
        top: 0;
        transform: translate(-90%, -105%);
      }
    }

    &:after {
      position: absolute;
      transform: rotate(45deg);
      right: 5px;
      top: -9px;
      content: "";
      width: 15px;
      height: 15px;
      background: #ffffff;
      border-left: 1px solid rgba(90, 79, 255, 0.1);
      border-top: 1px solid rgba(90, 79, 255, 0.1);
    }

    &.last {
      &:after {
        border-left: none;
        border-top: none;
        border-right: 1px solid rgba(90, 79, 255, 0.1);
        border-bottom: 1px solid rgba(90, 79, 255, 0.1);
        bottom: -10px;
        top: unset;
      }
    }
  }

  &-button {
    font-family: JosefinSans;
    font-size: 14px;
    border: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: white;
    white-space: nowrap;

    img {
      margin-right: 15px;
      max-width: 12px;
      max-height: 15px;
    }

    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }
}

.button {
  background-color: $main-black;
  height: 50px;
  border-radius: 25px;
  border-width: 1px;
  border-color: $main-black;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 16px;
  font-family: JosefinSans;
  &--mt-2 {
    margin-top: 20px;
  }
  img {
    margin-right: 15px;
  }

  &-outlined {
    background: white;
    color: $main-black;
  }

  &-small {
    height: 36px;
    max-width: 160px;
    font-size: 14px;
  }

  &-limited {
    max-width: 250px;
  }

  &-red {
    border-color: #ED2121;
    color: #ED2121;
    font-weight: 600;
  }

  &-compact {
    width: auto;
    padding: 8px 16px;
  }

  &--right-icon {
    img {
      margin-right: 0px;
      margin-left: 8px;
      width: 7px;
      height: 7px;
    }
  }

  &-text {
    width: fit-content;
    height: fit-content;
    background-color: transparent;
    border: none;
    color: $main-black;
    font-size: 18px;
  }

  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }

  &-disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}

.small-uppercase {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 15px;
  letter-spacing: 2px;
}

.selected-filter-button {
  background-color: rgb(224, 224, 224);
}

.recipe-card {
  margin-top: 30px;
  width: 100%;
  border: 1px solid #dbdbdb;
  border-radius: 10px;
  display: flex;
  flex-direction: column;

  .body {
    flex-grow: 1;
    padding: 25px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .image {
    background-size: cover;
    background-position: center;
    height: 130px;
    border-radius: 9px 9px 0 0;
    display: flex;
    justify-content: flex-end;
    padding: 15px;
  }

  .name {
    font-family: JosefinSans;
    font-size: 14px;
    line-height: 15px;
    min-height: 30px;
    margin-bottom: 15px;
  }

  .info {
    display: flex;

    .info-element {
      display: flex;
      align-items: center;
      font-family: JosefinSans;
      font-size: 14px;

      img {
        margin-right: 10px;
      }

      &:not(:last-child) {
        margin-right: 40px;
      }
    }
  }
}

.tabs {
  height: 100%;
  display: flex;
  flex-direction: column;

  .buttons {
    z-index: 1;
    display: flex;

    button {
      flex-grow: 1;
      font-family: JosefinSans;
      font-size: 18px;
      color: $gray;
      padding: 20px 0;
      background-color: transparent;
      border: 1px solid #f3f2f2;
      border-bottom: none;
      border-radius: 10px 10px 0 0;
      text-transform: uppercase;
      font-weight: 600;

      &.active {
        font-family: JosefinSans;
        color: $main-black;
        border-color: $gray;
        background-color: #f3f2f2;
      }

      &:focus {
        outline: none;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  .body {
    flex-grow: 1;
    margin-top: -1px;
    border: 1px solid $gray;
    border-radius: 0 0 10px 10px;
    padding: 40px;

    &.rounded-left {
      border-top-left-radius: 10px;
    }

    &.rounded-right {
      border-top-right-radius: 10px;
    }
  }
}

.tab-content {
  .button-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;

    a {
      max-width: 250px;
      width: 100%;
    }
  }
}

.article .photo,
.event .photo {
  border-radius: 10px;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: 200px;
  background-size: cover;
  background-position: center;
}

.article {
  margin-bottom: 20px;

  &.hoverable {
    cursor: pointer;
  }

  .title {
    font-family: JosefinSans;
    font-size: 22px;
    margin-bottom: 10px;
    margin-top: 20px;

    &.hoverable {
      cursor: pointer;
    }
  }
}

.event {
  margin-bottom: 20px;

  &.hoverable {
    cursor: pointer;
  }

  .title {
    font-family: JosefinSans;
    font-size: 16px;
    margin-bottom: 10px;
    margin-top: 15px;

    &.hoverable {
      cursor: pointer;
    }
  }

  .location-container {
    display: flex;
    align-items: center;

    img {
      height: 16px;
      margin-right: 15px;
    }
  }

  .location {
    font-family: JosefinSans;
    font-size: 14px;
    color: $gray;
  }
}

.badge {
  background: $main-black;
  border-radius: 15px;
  font-size: 12px;
  color: white;
  padding: 5px 15px;
  font-family: JosefinSans;
}

.table {
  margin-top: 40px;
  position: relative;
  border-collapse: separate;
  border-spacing: 0 10px;
  // width: 100%;
  // min-width: max-content;
  &-container {
    display: flex;
    flex-direction: column;
  }

  th {
    font-family: JosefinSans;
    font-size: 12px;
    padding: 0 15px;
    text-align: left;
    padding-bottom: 10px;

    &:hover {
      cursor: pointer;
    }

    & > div {
      display: flex;
      align-items: center;
      height: 14px;
    }

    .sorting {
      display: flex;
      flex-direction: column;
      margin-left: 10px;

      img {
        width: 10px;

        &:not(:last-child) {
          margin-bottom: 3px;
        }
      }
    }
  }

  tr {
    border-radius: 10px;

    td {
      padding: 15px;
      border-top: 1px solid #dbdbdb;
      border-bottom: 1px solid #dbdbdb;
      font-family: JosefinSans;
      font-size: 14px;
      justify-content: space-between;

      &:first-child {
        border-left: 1px solid #dbdbdb;
      }

      &:last-child {
        border-right: 1px solid #dbdbdb;
      }
    }
  }

  tr td:first-child {
    border-top-left-radius: 10px;
  }
  tr td:first-child {
    border-bottom-left-radius: 10px;
  }
  tr td:last-child {
    border-top-right-radius: 10px;
  }
  tr td:last-child {
    border-bottom-right-radius: 10px;
  }
}

.fixed-column {
  overflow: hidden;
  text-overflow: ellipsis;

  &-user {
    width: 200px;
  }

  &-email {
    width: 100px;
  }

  &-location {
    width: 100px;
  }

  &-status {
    width: 110px;
  }

  &-type {
    width: 100px;
  }
}

.not-found {
  font-size: 26px;
  margin-top: 48px;
}

.user-profile {
  display: flex;
  align-items: center;

  .photo {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    background-size: cover;
    background-position: center;
    margin-right: 15px;
    &.background {
      border-radius: 0;
      width: 100px;
    }
  }
}

.passions {
  display: flex;
  flex-wrap: wrap;

  .passion {
    padding: 5px 10px;
    border-radius: 20px;
    background: #f0efef;
    font-family: JosefinSans;
    font-size: 12px;
    margin-bottom: 5px;
    text-align: center;

    &:not(:last-child) {
      margin-right: 5px;
    }
  }
}

.image-upload {
  &-new {
    margin-bottom: 20px;
    background-size: contain;
  }
  .error {
    min-height: 15px;
    font-size: 10px;
    font-family: JosefinSans;
    color: red;
  }
  .size {
    padding: 16px;
    font-weight: 600;
    padding: 0;
    margin-bottom: 10px;

    & > span {
      color: #00000099;
    }
  }
  .image-preview {
    transition: 0.3s;
    height: 350px;
    background-size: contain;
    background-position: left;
    background-repeat: no-repeat;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    &.new {
      height: 200px;
      background-size: contain;
    }
    &.empty {
      background: #dbdbdb;
    }

    &.loading {
      opacity: 0.5;
    }

    .loader {
      position: absolute;
    }
  }

  .buttons {
    display: flex;

    & > * {
      &:not(:last-child) {
        margin-right: 40px;
      }
    }
  }
}

.add-info {
  margin: 40px 0;

  &.no-margin {
    margin: 0;
  }

  h6 {
    font-family: JosefinSans;
    font-size: 18px;
    margin-bottom: 20px;
  }
}

.pdf-uploader {
  display: flex;
  flex-direction: column;

  .area {
    height: 80px;
    border: 1px dashed #dbdbdb;
    border-radius: 7px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: JosefinSans;
    font-size: 14px;
    color: $gray;

    &.selected {
      color: $main-black;
    }

    &:hover {
      cursor: pointer;
    }
  }
}

.label {
  margin-bottom: 10px;
  font-family: JosefinSans;
  font-size: 16px;
}
.addInfo-label {
  margin-bottom: 10px;
}

h6.page-section {
  font-family: JosefinSans;
  font-size: 18px;
  margin-bottom: 15px;
  margin-top: 40px;
}

.title-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  margin-top: 40px;

  .title-wrapper {
    display: flex;

    button {
      margin-left: 10px;
      background: transparent;
      border: none;
      color: $gray;
    }
  }

  h6 {
    margin: 0;
  }

  .title-counter {
    display: flex;
    align-items: center;

    p {
      font-family: JosefinSans;
      font-size: 14px;
      margin-right: 10px;
    }
  }
}

.action-buttons {
  margin-top: 40px;
  display: flex;

  .publish-button {
    margin: 0 10px;
  }
  .draft-button {
    margin: 0 10px;
  }
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 200px;

  .input-container {
    width: 100%;
  }

  button {
    margin-top: 40px;
  }
}

.allergens-list {
  display: flex;
  flex-wrap: wrap;

  & > div {
    width: 150px;
    margin-bottom: 10px;
  }
}

.chip-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  height: 44px;
  gap: 10px;
}

.chip {
  box-sizing: border-box;
  align-items: center;
  padding: 8px 10px;
  border-radius: 26px;
  background-color: $light-gray;
  font-size: 16px;
  font-family: JosefinSans;
  width: auto;
  display: flex;
  background-color: #E2E2E2;
  height: max-content;
  color: #0D0D0D8C;
  font-weight: 700;

  .remove-button {
    width: 18px;
    height: 18px;
    border-radius: 9px;
    margin-right: -5px;
    margin-left: 10px;
    background-color: $main-black;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      cursor: pointer;
    }

    img {
      width: 8px;
      height: 8px;
    }
  }
}

.status {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  border-radius: 6px;
  background-color: $light-gray;
  color: $main-black;
  cursor: default;

  &-success {
    background-color: #b7e0b7;
    color: #478251;
  }

  &-warning {
    background-color: #f6f0b4;
    color: #ca8a0d;
  }

  &-error {
    background-color: #f2c9c7;
    color: #8d1c1c;
  }
}

.modal-overlay {
  transition: 0.2s ease-in-out;
  background: rgba($main-black, 0.2);
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  visibility: hidden;
  opacity: 0;
  z-index: 9999;

  &.loading {
    .modal {
      width: auto;
    }

    .modal-buttons {
      display: none !important;
    }
  }

  &.active {
    visibility: visible;
    opacity: 1;

    .modal {
      opacity: 1;
    }

    &.modal-flex-between {
      & .modal-buttons {
        justify-content: space-between;
      }
    }
  }

  .modal {
    transition: 0.3s ease-in-out;
    opacity: 0;
    max-width: calc(100vw - 30px);
    width: 450px;
    background: white;
    border-radius: 10px;
    border: 1px solid #dbdbdb;
    padding: 30px;
    position: relative;

    .page-section {
      margin-top: 0;
    }
    .close-button {
      background: #000;
      border: none;
      border-radius: 50%;
      padding: 4px;
      position: absolute;
      display: flex;
      justify-content: center;
      align-self: center;
      right: 5px;
      top: 5px;
      & > img {
        width: 10px;
        aspect-ratio: 1/1;
      }
    }
    .modal-buttons {
      display: flex;
      justify-content: flex-end;
      margin-top: 10px;

      .button {
        &:not(:last-child) {
          margin-right: 10px;
        }
      }
    }
  }
}

.checkboxes-list {
  width: 100%;
  margin-top: 12px;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-360deg);
  }
}

.loader {
  animation-name: rotate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

.recipes-loading,
.meals-loading {
  transition: 0.3s;
  opacity: 0.5;
  pointer-events: none;
}

.permission {
  margin-bottom: 15px;

  p {
    font-family: JosefinSans;
    font-size: 14px;
  }
}

//.box {
//  width: 100%;
//  max-width: 500px;
//  border-radius: 10px;
//  padding: 100px;
//  background: white;
//}

.password-button {
  padding: 0;
  margin: 0 5px 0 0 !important;
  border: none;
  background: none;
  color: #48bd18;
  font-family: JosefinSans;
}

.text-muted {
  color: $gray;
  font-family: JosefinSans;
}

.review {
  padding: 20px 25px;
  border: 1px solid $light-gray;
  border-radius: 10px;
  margin-bottom: 25px;
  font-family: JosefinSans;

  .info-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .info span {
    font-family: JosefinSans;
  }

  .extra {
    display: flex;
    align-items: center;

    .tooltip {
      margin-left: 10px;
    }
  }

  .message {
    margin: 10px 0;
  }

  .date {
    color: $gray;
  }
}

.rating {
  display: flex;
  align-items: center;

  img:not(:last-child) {
    margin-right: 5px;
  }
}

.meal-button {
  display: flex;
  margin-bottom: 20px;

  .button:first-child {
    margin-right: 20px;
  }
}

.ingredients-modal {
  .modal {
    width: 550px;
    max-height: 70vh;
    display: flex;
    flex-direction: column;

    & .modal-buttons {
      justify-content: space-between;
    }
  }

  .rounded-input {
    width: calc(100% - 52px);
  }
  .body {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    flex: 1;

    .empty-message {
      text-align: center;
      font-size: 20px;
      color: $gray;
      font-family: JosefinSans;
      margin: 30px 0;
    }
  }
}

.ingredient-buttons {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 15px;
  flex-shrink: 1;
  overflow-y: scroll;

  .ingredient-button {
    font-family: JosefinSans;
    font-size: 14px;
    text-align: left;
    margin-bottom: 5px;
    text-transform: capitalize;
    border: 1px solid $main-black;
    width: 97%;
    display: flex;
    flex-direction: row;
    align-content: space-between;
    justify-content: space-between;
    border-radius: 5px;
    background: white;
    max-width: 100%;
    &:hover {
      background: #fafafa;
      border-color: #ccc;
    }
    span {
      color: $gray;
      font-size: 12px;
    }
    button:not(.delete) {
      width: 80%;
      padding: 5px;
      border: none;
      background: none;
      text-align: left;
      padding-left: 10px;
    }
    button.delete {
      width: 17px;
      padding: 3px;
      height: 17px;
      border: none;
      background: none;
      background: url("../images/common/trash.svg");
      background-size: 10px 10px;
      background-repeat: no-repeat;
      background-position: center;
      margin-top: 3px;
      margin-right: 10px;
    }
    button.delete:hover {
      border: solid 1px black;
      border-radius: 100%;
    }
  }
}
.ingredient-calories {
  font-size: 10px !important;
  color: #000 !important;
  font-weight: 600;
  padding-left: 20px;
  &::after {
    content: " Kcal";
  }
}
.ingredient-brand {
  font-size: 10px;
  color: #ccc;
}
.ingredient-nutrition {
  color: #999;
  font-size: 10px;
  font-style: italic;
  display: block;
  margin-bottom: 10px;
  font-weight: 300 !important;
  font-family: Arial, Helvetica, sans-serif;
  padding-left: 5px;
}
p.plain {
  font-weight: 300 !important;
  font-family: Arial, Helvetica, sans-serif;
}
.ingredient-name {
  display: flex;
  align-items: center;
  position: relative;

  & > .input-container {
    margin-bottom: 0;
  }

  .heading-title {
    font-weight: 700;
    width: 100%;
  }

  &.heading {
    background-color: #0D0D0D1A;
  }

  .ingredient-options {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 16px;
    width: 10px;
    margin-right: 10px;
    &.first {
      top: 40px;
      width: 16px;
      left: -25px;
    }
    button {
      margin-left: 5px;
      display: inline-block;
      width: 16px;
      height: 16px;
      border: none;
      padding: 0px;
      outline: none;
      &.delete-button {
        background-image: url("../images/common/trash.svg") !important;
        background-size: 16px 16px;
        background-position: center;
        background-repeat: no-repeat;
      }
      &.swap {
        &.up {
          background: url("../images/common/up.svg");
          background-size: 16px 16px;
          background-position: center;
          background-repeat: no-repeat;
          left: -45px;
        }
        &.down {
          background: url("../images/common/up.svg");
          background-size: 16px 16px;
          background-position: center;
          background-repeat: no-repeat;
          transform: rotate(180deg);
          left: -65px;
        }
      }
      &:hover {
        opacity: 0.5;
      }
    }
  }

  .input-container {
    flex-grow: 1;
  }

  .search-button {
    margin-left: 15px;
    border: none;
    background: white;
    margin-bottom: 14px;
    font-size: 16px;
    font-family: JosefinSans;
    color: $gray;
    &:hover {
      color: $main-black;
    }
  }
}
.input-container,
.select-container {
  width: 100%;
  span:empty {
    display: none;
  }
  span {
    font-size: 10px !important;
    padding-top: 3px;
    &::before {
      content: "* ";
    }
  }
}

.recipes-modal {
  max-height: 100vh;
  align-items: flex-start;
  overflow-y: scroll;
  .modal {
    margin: 50px 0;
  }
}
.settings-modal {
  max-height: 100vh;
  align-items: center;
  overflow-y: scroll;
  .modal {
    margin: 50px 0;
  }
}

.quotes-title {
  margin-bottom: 20px;
}

.confirm-modal {
  max-height: 100vh;
  align-items: flex-start;
  overflow-y: scroll;
  .modal {
    margin: 50px 0;
    div {
      text-align: center;
      margin: 10px 0 20px;
    }
    .modal-buttons {
      display: flex;
      justify-content: space-around;
    }
  }
}

.password-reset-modal {
  .input-container {
    margin: 30px 0;
  }
}

.manage-forms {
  max-height: 500px;
  overflow-y: scroll;
  margin-bottom: 10px;

  .form {
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    font-family: JosefinSans;

    p {
      padding: 5px;
      max-width: 80%;

      &.editing {
        border: 1px solid $gray;
      }

      &.withError {
        color: red;
      }
    }

    .form-buttons {
      button {
        background: transparent;
        border: none;
        margin-left: 10px;
        padding: 5px;

        img {
          height: 15px;
        }
      }
    }
  }
}
.textarea-container {
  margin-bottom: 5px;
  & > label {
    margin-bottom: 20px;
    width: 100%;
    font-family: JosefinSans;
    font-size: 16px;
  }
  & > textarea {
    width: 100%;
    min-height: 60px;
    overflow: hidden;
    resize: none;
    border: 1px solid #dbdbdb;
    border-radius: 7px;
    padding: 10px 15px;
    flex-grow: 1;
    margin-top: 10px;
    font-size: 14px;
    font-family: JosefinSans;
    max-width: calc(100% - 30px);
    min-width: calc(100% - 30px);
    resize: none;
  }
  & > .error {
    font-size: 10px;
  }
}
.page-limit {
  display: flex;
  align-items: center;
  &-title {
    width: 80px;
    font-weight: 400;
  }
  &-select {
    position: relative;
    & > div {
      margin-bottom: 0;
    }
  }
  &-img {
    position: absolute;
    top: 20px;
    transform: translateY(-50%);
    right: 10px;
  }
}

.aacp-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &-button {
    height: 50px;
    width: 100px;
    background: transparent;
    border: none;
    display: flex;
    gap: 10px;
    align-items: center;
    font-size: 20px;

    & > img {
      width: 18px;
      height: 18px;
    }
  }
}

.plan-image {
  height: 150px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 5px;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

.plan-card {
  position: relative;

  &.with-caption {
    &::before {
      position: absolute;
      top: -40px;
      left: 0px;
      font-size: 24px;
      font-weight: 800;
      line-height: 30px;
      letter-spacing: 0.54px;

      color: #0d0c0c;
    }
  }
  &.this-week::before {
    content: "Latest Plans";
  }
  &.saved-plans::before {
    content: "Saved Plans";
  }
  &.create .plan-image {
    transition: 0.15s;
    border: 1px dashed $gray;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $gray;
    font-size: 64px;
    font-weight: 400;

    &:hover {
      color: $main-black;
      border-color: $green;
    }
  }

  .plan-image {
    height: 150px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 5px;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
  }
  .plan-tooltip-button {
    position: relative;
    width: 80%;
    .tooltip {
      position: absolute;
      right: 8px;
      top: 16px;
    }
  }
  .plan-name {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;

    .button {
      text-align: left;
      width: 100%;
      display: flex;
      position: relative;
      font-size: 15px;
      text-transform: uppercase;
      font-weight: 800;
      letter-spacing: 1px;

      background: rgb(238, 235, 235);
      padding: 12px 28px;
      border-radius: 3.52381px;

      &.disabled {
        background-color: rgba(212, 30, 30, 0.8);
      }
    }
  }
  .button {
    background: white;
    color: $main-black;
    border: none;
  }
}

.edit-user-actions {
  width: 50%;
  display: flex;
  justify-content: space-between;
}

.forgot-password-link {
  margin-top: 10px;
  align-self: flex-start;
  text-decoration: underline;
  color: black;
}

.restore-password {
  width: 30%;
  align-self: center;
}

.select-component {
  position: relative;
  width: 340px;
  min-height: 42px;
  font-family: "Arial", sans-serif;
}

.select-input {
  width: 100%;
  min-height: 42px;
  padding: 10px 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 18px;
  outline: none;
  cursor: pointer;
  transition: border-color 0.3s ease;
}

.select-input:focus {
  border-color: #007bff;
}

.dropdown {
  position: absolute;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  max-height: 200px;
  overflow-y: auto;
}

.option {
  padding: 10px 15px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.option:hover {
  background-color: #f0f0f0;
}

.option:active {
  background-color: #e0e0e0;
}

.create-option {
  font-weight: bold;
  color: #007bff;
}

.custom-select-1 {
  width: max-content;
  display: flex;
  align-items: center;
  gap: 10px;

  .label {
    margin: 0;
    font-size: 13px;
    line-height: 23px;
    font-weight: 700;
    color: black;
    text-transform: uppercase;

    @media screen and (min-width: 2000px) {
      font-size: 20px;
      line-height: 23px;
    }
  }

  .select {
    position: relative;
    .toggle {
      border: none;
      outline: none;
      background-color: transparent;
      font-size: 13px;
      line-height: 23px;
      font-weight: 700;
      color: black;
      text-decoration: underline;
      text-transform: uppercase;
      font-family: JosefinSans;

      @media screen and (min-width: 2000px) {
        font-size: 20px;
        line-height: 23px;
      }
    }

    .options-wrapper {
      width: 120px;
      position: absolute;
      z-index: 10;
      left: 0;
      top: 100%;
      background-color: #fff;
      padding: 10px;
      border-radius: 6px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      box-shadow: 10px 10px 24px rgba(0, 0, 0, 0.25);

      @media screen and (min-width: 2000px) {
        width: 140px;
      }

      .option {
        text-transform: uppercase;
        font-size: 14px;
        line-height: 23px;
        font-weight: 600;
        color: black;
        transition: font-weight 0.25s ease-out;
        padding: 10px 0;
        cursor: pointer;

        @media screen and (min-width: 2000px) {
          font-size: 20px;
          line-height: 23px;
        }

        &:hover {
          font-weight: 900;
        }
      }
    }
  }
}

.fullscreen-modal {
  transition: 0.3s ease-in-out;
  visibility: hidden;
  opacity: 0;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: 100vh;
  max-height: 100%;
  background-color: #f3f2f2;
  z-index: 99;
  overflow-y: scroll;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  .scrollable-layer {
    padding: 40px 0;
    display: flex;
    justify-content: center;
    min-height: calc(100% - 80px);

    @media (max-width: $breakpoint-mobile-m) {
      padding: 20px 0;
      min-height: calc(100% - 40px);
    }
  }

  &.visible {
    visibility: visible;
    opacity: 1;
  }

  .button-container {
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    top: 40px;
    // left: 100%;
    // transform: translateX(-50%);
    z-index: 3;
    width: calc(100% - 80px);
    max-width: 1200px;

    // @media (max-width: $breakpoint-laptop) {
    //   margin-bottom: 20px;
    // }

    @media (max-width: $breakpoint-mobile) {
      width: calc(100% - 40px);
    }

    &.with-action {
      @media (max-width: $breakpoint-laptop) {
        justify-content: end;

        .close-button {
          width: 26px;
          height: 26px;
          background-color: black;
          border-radius: 100%;
          margin-bottom: 10px;
        }

        .mobile-action {
          display: flex;
        }
      }
    }

    .close-button {
      position: fixed;
      top: 20px;
      right: 20px;
      width: 26px;
      height: 26px;
      background-color: black;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
    }

    .mobile-action {
      display: none;
    }
  }

  .content {
    height: auto;
    max-height: 100%;
    width: 100%;
    padding: 80px 40px 0;
    max-width: 1200px;

    @media (max-width: $breakpoint-mobile) {
      padding: 60px 20px 0;
    }

    @media (max-width: $breakpoint-mobile-m) {
      padding: 80px 20px 0;
    }
  }
}

.recipes-filter {
  .show-recipes {
    border-radius: 8px;
  }
  .top-options {
    margin-bottom: 40px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;

    .option {
      width: 100%;
      height: 50px;
      background-color: white;
      border: none;
      // font-family: JosefinSans;
      font-weight: 600;
      font-size: 18px;
      line-height: 23px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;

      @media screen and (min-width: 2000px) {
        font-size: 22px;
      }

      &:before,
      &:after {
        display: none;
      }
      &.active {
        background-color: $main-black;
        color: white;

        @media screen and (max-width: 768px) {
          background-color: #2e4741;
        }

        .check {
          display: block;
        }
      }

      .check {
        margin-right: 5px;
        display: none;
      }

      .rating-option img {
        margin-right: 2px;
      }
    }
  }
  @media (min-width: ($breakpoint-laptop + 1)) {
    // max-height: calc(100vh - 240px);
    overflow-y: scroll;
    overflow-x: visible;
    padding: 40px 32px 50px 32px;

    &::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .filter-statistics {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // font-family: AvenirHeavy;
    font-weight: 700;
    @media (max-width: ($breakpoint-laptop + 1)) {
      margin-bottom: 30px;
    }
    .title {
      display: flex;
      align-items: center;
      font-size: 18px;
      letter-spacing: 0.5px;

      @media screen and (min-width: 2000px) {
        font-size: 22px;
      }

      @media (min-width: $breakpoint-laptop) {
        display: none;
      }

      .count {
        margin-left: 10px;
        height: 20px;
        width: 20px;
        border-radius: 10px;
        background-color: $green;
        color: white;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        letter-spacing: 0;
      }
    }
    .clear-all {
      @media (min-width: $breakpoint-laptop) {
        display: none;
      }
    }
  }

  .slider-container {
    display: flex;
    margin: 0 4px;
    overflow: visible;
    .input-range {
      margin-top: 30px;
      margin-bottom: 20px;
    }
  }
}

.recipe-card-new {
  box-shadow: 0 0px 10px 0px rgba(#254215, 0.06);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;

  @media (max-width: $breakpoint-mobile-m) {
    height: 90px;
    flex-direction: row;
  }

  &:hover {
    cursor: pointer;
  }

  .image {
    height: 160px;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: flex-end;

    @media screen and (min-width: 2000px) {
      height: 230px;
    }

    @media (max-width: $breakpoint-mobile-m) {
      min-width: 90px;
      height: 100%;
      border-radius: 10px 0 0 10px;

      .info-button {
        display: none;
      }
    }
  }

  .info-container {
    .title {
      font-size: 18px;
      line-height: 18px;
      font-weight: 600;

      @media screen and (min-width: 2000px) {
        font-size: 26px;
        line-height: 26px;
      }
    }

    .text-muted {
      font-size: 14px;
    }

    .title:hover {
      cursor: pointer;
    }
    @media (max-width: $breakpoint-mobile-m) {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }
  }

  .info-button {
    height: 40px;
    width: 40px;
    background-color: black;
    border: none;
  }

  .info {
    padding: 15px;
    display: flex;
    align-items: center;
    line-height: 18px;
    min-height: 36px;

    @media (max-width: $breakpoint-mobile-m) {
      align-items: flex-start;
      font-size: 16px;
      line-height: 15px;
      min-height: 30px;
      padding-bottom: 10px;
      padding-top: 18px;
      padding-right: 8.5px;

      .heart {
        width: 13.5px;
        margin-left: 8px !important;
      }
    }

    p {
      flex-grow: 1;
    }

    .heart {
      margin-left: 15px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .statistics {
    padding: 5px 15px 25px;
    display: flex;
    flex-grow: 1;
    align-items: flex-end;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;

    .info-button {
      display: none;
      height: 30px;
      width: 30px;
      align-items: center;
      justify-content: center;
      right: 0;
      bottom: 0;

      img {
        height: 12px;
      }
    }

    @media (max-width: $breakpoint-mobile-m) {
      padding: 0 15px 18px;

      .text-muted {
        font-size: 12px;
      }

      .info-button {
        position: absolute;
        display: flex;
      }
    }

    .element {
      display: flex;
      align-items: center;
      white-space: nowrap;

      img {
        position: relative;
        top: -2px;
        height: 15px;
        width: 13px;
        margin-right: 5px;

        @media (max-width: $breakpoint-mobile-m) {
          height: 10px;
          width: auto;
        }
      }

      .text-muted {
        color: black;

        @media screen and (min-width: 2000px) {
          font-size: 20px;
        }
      }

      &:last-child {
        margin-right: 20px;
      }

      @media screen and (max-width: 475px) {
        &:nth-child(2) {
          margin-right: 20px;
        }
      }
    }
  }
}

.filters-search {
  display: flex;
  flex-direction: column;
  gap: 28px;
  margin-bottom: 28px;

  @media screen and (max-width: 1200px) {
    display: none;
  }

  .search-input > .input-container > .wrapper > input {
    height: 50px;
    border-radius: 0;

    @media screen and (min-width: 2000px) {
      font-size: 22px;
    }
  }

  .filter-statistics {
    justify-content: center;
    align-items: center;
    gap: 10px;

    .divider {
      height: 15px;
      width: 2px;
      background-color: #fff;
      transform: translateY(-3px);
    }

    .title {
      font-size: 14px;
      line-height: 14px;
      text-transform: uppercase;
      color: white;
      @media (min-width: $breakpoint-laptop) {
        display: flex;
      }

      .count {
        background-color: transparent !important;
        font-size: 14px;
        line-height: 14px;
        text-transform: uppercase;
        margin-left: 5px;
      }
    }
    .clear-all {
      font-size: 14px;
      line-height: 14px;
      text-transform: uppercase;
      text-decoration: none;
      background-color: transparent;
      font-weight: 800;
      color: white;
      transition: color 0.25s ease-in;
      @media (min-width: $breakpoint-laptop) {
        display: block;
      }

      &:hover {
        color: rgb(112, 175, 16);
      }
    }
  }
}

.add-recipe {
  margin: 0 !important;
  display: flex;

  .sidebar-new {
    min-width: 410px;
    background: #2e4741;
    z-index: 1;
    border-radius: 0;
    position: static;
    .section-header {
      height: 40px;
      margin-bottom: 30px;
    }
    .tabs {
      z-index: 2;
    }
    @media (max-width: 1199px) {
      position: relative;
      width: 100%;
      max-width: 280px;
      margin: 10px auto 0;
      .section-header,
      .filter-statistics {
        display: none;
      }
    }

    &-modal {
      @media (max-width: 1199px) {
        min-width: auto;
        width: 100vw;
        max-width: 100vw;
        padding: 80px 15px 15px;
        background: #eeeeee;
      }
    }
  }
  .content-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-left: 25px;
    @media (max-width: 1199px) {
      &.hidden {
        display: none;
      }
    }
    .section-header {
      margin-bottom: 25px;

      @media (min-width: $breakpoint-laptop) {
        margin-bottom: 0;
      }

      @media (max-width: $breakpoint-mobile-m) {
        margin-bottom: 10px;
      }
    }
    .search-container {
      display: flex;
      z-index: 2;
      height: 40px !important;
      display: none;
      @media (max-width: 1200px) {
        display: block;
        background-color: #2e4741;
        height: auto !important;
        width: 100%;
        transform: translateX(-20px);
        padding: 20px;
        &.inside-modal {
          width: calc(100vw - 80px);
        }
      }
      @media (max-width: $breakpoint-mobile-m) {
        margin: 0 0 15px;
        &.inside-modal {
          width: calc(100vw - 40px);
        }
      }
      .search-input {
        width: 100%;
      }
      .mobile-search {
        border: none;
        border-radius: 6px;
      }
      .buttons {
        @media screen and (max-width: 1200px) {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 20px;

          .divider {
            height: 14px;
            width: 2px;
            background-color: #fff;
            transform: translateY(-2px);
          }
        }
      }
      .filter-button {
        width: 155px;
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid rgba(0, 0, 0, 0.3);
        border-radius: 20px;
        background: white;
        margin-left: 20px;

        img {
          display: none;
        }

        @media (min-width: $breakpoint-laptop) {
          display: none;
        }

        @media (max-width: $breakpoint-laptop) {
          background-color: transparent;
          border: none;
          color: white;
          width: auto;
          height: auto;
          border-radius: 0;
          margin-left: 0;
          gap: 6px;
          img {
            display: block;
          }
          p {
            text-transform: uppercase;
          }
          img {
            display: block;
            position: relative;
            top: -2px;
          }

          & > .small-uppercase {
            font-size: 16px;
            line-height: 14px;
          }
        }

        span {
          // font-family: AvenirHeavy;
          font-weight: 700;
          margin-right: 10px;
          height: 20px;
          width: 20px;
          border-radius: 10px;
          background-color: $green;
          color: white;
          font-size: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          letter-spacing: 0;
        }
      }
    }
    .filter-statistics {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 40px;
      display: none;

      @media (max-width: 1199px) {
        display: none;
      }
      .title {
        display: flex;
        align-items: center;
        font-size: 18px;
        letter-spacing: 0.5px;
        .count {
          margin-right: 10px;
          color: black;
          // font-size: 11px;
        }
      }
      .clear-all {
        // font-family: AvenirHeavy;
        font-size: 18px;
        margin-left: 10px;
        padding-left: 10px;
        background-color: inherit;
        border-radius: 0;
        border-left: #000 2px solid;
      }
    }
    .recipes-container {
      width: auto;
      margin-top: 10px;
      h2 {
        z-index: 2;
        position: fixed;
      }

      @media (max-width: 1199px) {
        padding-left: 0;
      }

      @media screen and (max-width: 475px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 0;
      }
    }
    @media (max-width: 1199px) {
      margin-left: 0;
    }
  }
}

.recipes-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 15px;
  margin-top: 40px;
  @media (max-width: 475px) {
    grid-template-columns: 1fr;
  }

  &.grid-4 {
    grid-template-columns: repeat(4, 1fr);
  }

  &.grid-2-2 {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
}

.recipes-grid-container {
  .recipes-grid {
    margin-top: 10px !important;
  }
}

.recipes-grid {
  margin-top: 0;
}

.add-recipe {
  display: flex;
  @media (max-width: 1199px) {
    grid-template-columns: 1fr;

    & > div {
      display: none;

      &.visible {
        display: flex;
        flex-direction: column;
      }
    }
  }

  .recipes-grid {
    margin-top: 30px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    width: auto;
    @media (max-width: 930px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: 700px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 475px) {
      grid-template-columns: 1fr;
    }

    &.grid-4 {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}

.self-end {
  margin-left: auto;

  @media screen and (max-width: 475px) {
    margin-left: 0;
  }
}

.filter-element {
  margin-bottom: 35px;

  .filter-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    margin: -10px 0 5px 0;

    img {
      transition: 0.3s ease-in-out;

      &.rotate {
        transform: rotate(180deg);
      }
    }

    &:hover {
      cursor: pointer;
    }

    .small-uppercase {
      font-size: 24px;
      line-height: 24px;
      color: white;
      text-transform: uppercase;
      font-weight: 700;

      @media screen and (max-width: 1200px) {
        color: black;
      }
    }
  }

  .options {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;

    &.options-checkboxes {
      grid-template-columns: 1fr;

      .button-link {
        justify-content: flex-start;
      }
    }

    .option {
      height: 50px;
      background-color: white;
      border: none;
      font-family: JosefinSans;
      font-weight: 600;
      font-size: 16px;
      line-height: 23px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;

      @media screen and (min-width: 2000px) {
        font-size: 22px;
        line-height: 22px;
      }

      &.active {
        background-color: $main-black;
        color: white;

        @media screen and (max-width: 768px) {
          background-color: #2e4741;
        }

        // .check {
        //   display: block;
        // }
      }

      .check {
        margin-right: 5px;
        display: none;
      }

      .rating-option img {
        margin-right: 2px;
      }
    }
  }
}

.button-link {
  height: auto;
  width: auto;
  letter-spacing: 0;
  border: none;
  background: white;
}

.single-range {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-x: visible;
  position: relative;

  @media screen and (max-width: 1200px) {
    align-items: center;
  }

  .value {
    font-size: 16px;
    font-weight: 600;
    line-height: 14px;
    color: white;
    position: absolute;
    width: max-content;
    top: 13px;

    @media screen and (max-width: 1200px) {
      color: black;
    }
  }

  .range-thumb {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background: white;

    @media screen and (max-width: 1200px) {
      background: black;
    }
  }

  .range-track {
    width: 100%;
    height: 3px;
    border-radius: 6px;
    background-color: #fff;

    @media screen and (max-width: 1200px) {
      width: 88%;
      background-color: black;
    }
  }
}

.search-input {
  position: relative;
  display: flex;
  align-items: center;

  .input-container {
    width: 100%;
    margin-bottom: 0;

    .input {
      background-color: white;
      height: 42px;
      border: 1px solid #eaeaea;
      padding-right: 50px;

      &::placeholder {
        color: black;
      }
    }

    .error {
      display: none;
    }
  }

  .mark {
    position: absolute;
    right: 20px;
    width: 20px;
    aspect-ratio: 1/1;
    @media (max-width: 475px) {
      width: 12px;
    }
  }
}
.table-container {
  width: 100%;
  display: flex;
  overflow: auto hidden;

  &::-webkit-scrollbar {
    display: none;
  }

  &.with-scroll {
    overflow: auto visible;
  }
}

.table-settings {
  &__top {
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__iconButtons {
    display: flex;
    align-items: center;
    gap: 12px;
  }
  &__iconButton {
    border-radius: 50%;
    width: 48px;
    height: 48px;
    border: 1px solid #0d0c0c;

    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      max-width: 24px;
      max-height: 24px;
    }

    &:disabled {
      opacity: 0.3;
      cursor: not-allowed;
    }
  }
}

.row-settings {
  &__list {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
  }

  &__listItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    border-bottom: 1px solid #ccc;
  }

  &__hideButton {
    width: 24px;
    height: 24px;
    border: none;

    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  &__drag {
    display: flex;
    align-items: center;
    gap: 16px;
    text-transform: uppercase;
  }
  &__dragIcon {
    width: 20px;
    height: 20px;
    border: none;

    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

.multy-select {
  position: relative;
  min-width: 200px;
  &__label {
    font-size: 16px;
    line-height: 100%;
    font-weight: 700;
    margin-bottom: 8px;
  }
  &__input {
    cursor: pointer;
    display: flex;
    align-items: center;
    border: 1px solid #dbdbdb;
    max-height: 42px;
    height: fit-content;
    border-radius: 8px;
    padding: 10px 14px;
    font-size: 14px;
    line-height: 100%;
    color: #ccc;

    &--open {
      border: 1px solid black;
    }
  }

  &__placeholder {
    font-weight: 400;
    line-height: 20px;
  }

  &__selectedValue {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
  }

  &__selectedItems {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
    flex-wrap: wrap;
    height: 20px;
    overflow: hidden;
  }

  &__selectedItem {
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    background-color: #e4e4e4;
    color: black;
    padding: 4px 10px;
    border-radius: 12px;
    font-size: 12px;
    overflow: hidden;
    p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      line-height: 100%;
      font-weight: 400;
      text-transform: capitalize;
    }
  }
  &__selectedItemDelete {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 8px;
    height: 8px;
    background-color: transparent;
    border: none;
    border-radius: 50%;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__selectedCount {
    padding: 4px 10px;
    border-radius: 12px;
    font-size: 12px;
    background-color: black;
    color: white;
    line-height: 100%;
    font-weight: 400;
  }

  &__values {
    position: absolute;
    z-index: 10;
    transform: translateY(100%);
    bottom: -8px;
    left: 0;
    right: 0;
    background-color: white;
    padding: 8px;
    border-radius: 8px;
    box-shadow: 10px 10px 24px rgba(0, 0, 0, 0.25);

    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  &__valueItem {
    padding: 6px 8px;
    cursor: pointer;
    border-radius: 4px;
    font-weight: 400;
    font-size: 14px;
    text-transform: capitalize;
    &:hover {
      background-color: #f0f0f0;
    }

    &--selected {
      background-color: #ededed;
    }
  }
}

.page-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 400px;
  img {
    animation: rotation 3s infinite linear;
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.hide-1440 {
  @media screen and (max-width: 1440px) {
    display: none !important;
  }
}

.global-postfix {
  position: absolute;
  right: 15px;
  width: 30px;
  text-align: right;
  font-family: JosefinSans;
  font-size: 14px;
  
  .select-arrow {
    position: relative !important;
    opacity: 1 !important;
    animation: none !important;
    z-index: 10 !important;
    top: 12px !important;
    right: 0 !important;
    transition: all 0.3s ease-in;

    &.pure {
      top: 0px !important;
      right: 0 !important;
    }
  }

  &.active .select-arrow {
    transform: rotate(180deg);
    top: 10px !important;

    &.pure {
      top: 0px !important;
      right: 0 !important;
    }
  }
}

.custom-select {
  position: relative;
  width: 110px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
}

.select-selected {
  background-color: white;
  padding: 4px;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 12px;
  width: 100%;
  display: flex;
  justify-content: space-between;

  &.gray {
    color: gray;
  }

  .arrow {
    transition: transform 0.3s ease-in;
    &.active {
      transform: rotate(180deg);
    }
  }
}

.select-items {
  background-color: #fff;
  border: 1px solid #ccc;
  width: 100%;
  z-index: 1;
  display: block;
  font-size: 14px;
  position: absolute;
  bottom: 30px;
}

.select-item {
  padding: 4px;
  cursor: pointer;
}

.select-item:hover {
  background-color: #ddd;
}

.pt-0 {
  padding-top: 0 !important;
}

.meals-recipes-grid {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 10px;
}

.card-full {
  width: 100% !important;
  max-width: none !important;
}

.logo {
  height: 32px;
}

.sub-type-title {
  font-weight: 700;
  font-size: 16px;;
  margin-bottom: 8px;
}

.backgrounds {
  &-wrapper {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 2px solid black;
  }

  &-title {
    margin-bottom: 10px;
  }
}

.ql-snow .ql-tooltip[data-mode=link]::before {
  content: 'To link a page you must include https://';
}

.search-dropdown {
  position: relative;
  border: 1px solid #ccc;
  width: 100%;
  height: 460px;
  max-height: max-content;
  background-color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 16px 17px 26px;
  box-sizing: border-box;
  border-radius: 6px;
  margin: 0 !important;
  margin-top: 10px;
  position: absolute;
  z-index: 100;

  &-toggle {
    border: 1px solid #ccc;
    padding: 10px;
    cursor: pointer;
    background-color: #fff;
    border-radius: 6px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;

    &-text {
      width: 90%;
      font-size: 14px;
      line-height: 14px;
      font-weight: 600;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .postfix {
      transition: all 0.3s ease-in;

      &.active {
        transform: rotate(180deg);
      }
    }
  }

  &.with-trigger {
    margin-top: 40px !important;
  }

  &-search {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border-bottom: 1px solid #eee;
  }

  &-list {
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    height: 70%;
    max-height: max-content;
    padding-right: 5px;

    &.large {
      margin: 10px 0 20px;
    }
    
    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #0D0D0D70; 
      border-radius: 10px; 
      border: 2px solid transparent; 
    }

    &::-webkit-scrollbar-track {
      background-color: #0D0D0D2B;
      border-radius: 10px; 
      border: 2px solid transparent; 
    }
  }
  &-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    padding: 10px;
    cursor: pointer;

    & > p {
      width: 80%;
    }

    & .buttons {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    & button {
      border: none;
      outline: none;
      background-color: transparent;
    }

    &:nth-child(odd) {
      background-color: #0D0D0D05;
    }

    & .checkbox {
      margin-bottom: 0;
    }
  }

  .add-new-btn {
    width: 100%;
    background-color: #000;
    color: #fff;
    border: none;
    padding: 10px;
    cursor: pointer;
    margin-top: 10px;
    width: 212px;
    border-radius: 40px;
    font-weight: 600;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 6px;
    justify-content: center;
    
    & > img {
      border-radius: 50%;
      border: none;
      outline: none;
    }

    &-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .add-new-btn:hover {
    background-color: #333;
  }
}

.input-bold-title {
  & > .input-container > label {
    font-weight: 700;
  }
}

.slider {
  & .label {
    font-weight: 700;
  }

  & > .rc-slider {
    & > .rc-slider-rail, & > .rc-slider-step {
      background-color: #0D0D0D26;
    }
  }
}

.servings-counter {
  display: flex;
  flex-direction: column;
  gap: 10px;

  p {
    font-family: JosefinSans;
    font-size: 16px;
    font-weight: 700;
  }
}

.similar-title {
  font-family: JosefinSans;
  font-size: 18px;
  margin-bottom: 15px;
  margin-top: 40px;
  text-transform: uppercase;
}

.ingredient-row {
  box-sizing: border-box;
  border: 1px solid #0D0D0D14;
  border-radius: 6px;
  padding: 17px 0px 8px 6px;

  &.white {
    background-color: #fff;
  }

  &.heading {
    background-color: #0D0D0D1A;
    padding: 12px 0px 12px 6px;
  }
}

.delete-button {
  background-image: url("../images/common/trash.svg") !important;
  background-size: 24px 24px;
  background-position: center;
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  border: none;
  padding: 0px;
  outline: none;
  transform: translateY(60%);
  background-color: #fff;

  &.no-translate {
    transform: translateY(0);
    margin-left: 10px;
    background-color: transparent;
  }

  &-wrapper {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
}

.add-heading-title {
  font-weight: 700;
  margin-bottom: 10px;
}

.instructions-wrapper {
  background-color: rgba(255,255,255,0.8);
  border-radius: 6px;
  margin-top: 50px;
  padding: 22px 22px 22px 38px;

  & > * .page-section {
    margin-top: 0;
    font-weight: 700;
    font-size: 18px;
    text-transform: uppercase;
    margin-bottom: 24px;
  }

  & > * .textarea-container {
    & > label {
      font-weight: 700;
    }
    
    margin-bottom: 15px;
  }
}

.notes-wrapper {
  background-color: #0D0D0D14;
  border-radius: 6px;
  padding: 18px 16px;

  .note-title {
    display: flex;
    align-items: center;
    gap: 8px;

    & > p {
      font-weight: 700;
      font-size: 18px;
    }
  }

  & > * .quill {
    background-color: rgba(255,255,255,0.8);
    border-radius: 6px;
   
    & > .ql-toolbar {
      border-radius: 6px 6px 0 0;
      overflow: hidden;
    }

    & > .ql-container {
      border-radius: 0 0 6px 6px;
    }
  }
}

.overflow {
  overflow: visible;
}