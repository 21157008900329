@import "../../../node_modules/react-quill/dist/quill.snow.css";
@import "../../../node_modules/rc-slider/assets/index.css";
@import "../../../node_modules/animate.css/animate.css";

.ql-editor {
  font-family: JosefinSans;
}

.rc-slider {
  padding: 15px 0 20px;
}

.rc-slider-rail {
  height: 2px;
  border-radius: 1px;
}

.rc-slider-step,
.rc-slider-track {
  height: 2px;
}

.rc-slider-track,
.rc-slider-rail {
  background-color: $light-gray;
}

.rc-slider-handle {
  width: 10px;
  height: 10px;
  margin-top: -4px;
  border: none;
  background-color: $main-black;

  &:active {
    box-shadow: none;
  }
}

.rc-slider-tooltip {
  z-index: -1;
}

.rc-slider-tooltip-inner {
  background-color: transparent;
  color: black;
  font-family: JosefinSans;
  font-size: 14px;
  box-shadow: none;
}
.page-controller {
  display: flex;

  align-items: center;
  margin-top: 40px;
  & > .select-container {
    margin-bottom: 0;
  }
  @media (max-width: 767px) {
    flex-direction: column;
    row-gap: 10px;
  }

  &.users {
    align-items: flex-start;
  }
}
.pagination {
  width: 100%;

  display: flex;
  justify-content: center;
  
  .previous,
  .next {
    color: $main-black;
    
    &.disabled {
      color: #c5c3c3;

    }
  }

  ul {
    display: flex;
    list-style: none;

    li {
      border: 1px solid #dbdbdb;
      border-radius: 5px;
      font-family: JosefinSans;
      font-size: 16px;
      color: #c5c3c3;

      &:not(:last-child) {
        margin-right: 10px;
      }

      &.active {
        color: $main-black;
      }

      a {
        display: flex;
        line-height: 40px;
        height: 40px;
        width: 40px;
        align-items: center;
        justify-content: center;

        &:hover {
          cursor: pointer;
        }

        &:focus {
          outline: none;
        }
      }
    }
  }
}
