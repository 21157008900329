* {
  padding: 0;
  margin: 0;
  outline: none;
  box-shadow: none;
}

body {
  font-family: JosefinSans;
  & > iframe {
    pointer-events: none;
  }
}

a {
  text-decoration: none;
}

img {
  &.disabled {
    opacity: .5;
    pointer-events: none;
  }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

button {
  transition: .2s;

  &:hover {
    cursor: pointer;
  }
}
